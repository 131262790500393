import { styled } from "@material-ui/core";
import { sendSms } from "Api/sms";
import { default as useTranslation } from "Assets/hooks/useOLTranslation";
import { TelegramIcon } from "Assets/icons";
import { RequestContextStore } from "Components/Layout/AppLayout";
import { isValidPhoneNumber } from "libphonenumber-js";
import Notification from "Notifications/Notification";
import React, { useContext, useState, useEffect, useMemo } from "react";
import { useSelector, RootStateOrAny } from "react-redux";

import { defaultStates as defaultCrmStates } from "Store/Reducers/crmReducer";
import { defaultStates as defaultSmsStates } from "Store/Reducers/smsReducer";

import { validatePhoneNumbers } from "Utils/Utils";

const ChosenTemplate = (props): React.ReactElement => {
  const { translate } = useTranslation();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [htmlTemplate, setHtmlTemplate] = useState("");
  const [textArray, setTextArray] = useState([]);
  const totalCrmState = useSelector(
    (state: RootStateOrAny) => state.crmReducer,
  );
  const totalSmsState = useSelector(
    (state: RootStateOrAny) => state.smsReducer,
  );
  const requestContextStore: any = useContext(RequestContextStore);
  const crmState = requestContextStore.getCurrentStateFromTotalState(
    totalCrmState,
    defaultCrmStates,
  );
  const smsState = requestContextStore.getCurrentStateFromTotalState(
    totalSmsState,
    defaultSmsStates,
  );

  useEffect(() => {
    const number =
      crmState.identifiers &&
      crmState.identifiers.filter(
        (identifier) => identifier.channelType === "SIPPHONE",
      )[0]?.identifier;
    setPhoneNumber(validatePhoneNumbers(number));
  }, [crmState]);

  useEffect(() => {
    if (
      phoneNumber &&
      isValidPhoneNumber(phoneNumber, "AZ") &&
      phoneNumber.length === 12 &&
      phoneNumber.slice(3, 5) !== "12"
    )
      setIsValid(true);
    else setIsValid(false);
  }, [phoneNumber]);

  useEffect(() => {
    let { text } = smsState.chosenTemplate;
    text = text.toString().split(" ");
    const html = `<p>${text
      .map((word) => {
        if (word === "[]") return `<input type='number' class='sms' />`;
        else return `<span>${word}</span>`;
      })
      .join(" ")}</p>`;

    setHtmlTemplate(html);
    setTextArray(text);
  }, [smsState.chosenTemplate.text]);

  const showErrorModal = (message): void => {
    Notification.error(message);
  };

  const showSuccessModal = (): void => {
    Notification.success(translate("request_message_sent"));
  };

  const handleSmsSend = (): Promise<void> => {
    if (!isValid) return;
    props.setIsLoading(true);

    let currentIdx = 0;
    const nodeList = document.querySelectorAll(".sms"),
      resultText = textArray
        .map((text) => {
          if (text === "[]") return nodeList[currentIdx++]["value"];
          else return text;
        })
        .join(" ");
    const addInfoMessage =
      smsState.chosenTemplate.title === "OTP"
        ? "Birdefelik shifre: " + resultText
        : resultText;

    return sendSms(phoneNumber, addInfoMessage, smsState.chosenTemplate.title)
      .then((res) => {
        props.setIsLoading(false);
        if (res) showSuccessModal();
        else throw new Error();
      })
      .catch(() => {
        props.setIsLoading(false);
        showErrorModal(translate("request_message_cant_sent"));
      });
  };

  const handleKeyDown = (event): void => {
    if (event.key === "Enter") handleSmsSend();
  };

  return useMemo(
    () => (
      <Container>
        <Template dangerouslySetInnerHTML={{ __html: htmlTemplate }} />
        <InputContainer>
          <div>
            <Input
              type='text'
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              onKeyDown={handleKeyDown}
            />
            <div style={{ display: "inline-block", float: "left" }}>
              <SendIcon onClick={handleSmsSend} />
            </div>
          </div>

          {phoneNumber !== "" && !isValid && (
            <Warning>{translate("request_expected_format")}</Warning>
          )}
        </InputContainer>
      </Container>
    ),
    [phoneNumber, crmState, htmlTemplate, isValid],
  );
};

export default ChosenTemplate;

const Container = styled("div")({});

const Template = styled("div")({
  backgroundColor: "#F5F4F7",
  borderRadius: 12,
  color: "#020110",
  fontSize: 13,
  fontWeight: 600,
  padding: 12,
  lineHeight: "160%",
  letterSpacing: "0.02em",
  textAlign: "left",
  "& > p > input[type=number]": {
    backgroundColor: "inherit",
    color: "#020110",
    fontSize: 13,
    fontWeight: 600,
    lineHeight: "160%",
    letterSpacing: "0.02em",
    border: "none",
    outline: "none",
    borderBottom: "1px solid #DCDDE6",
    width: "10%",
    "-moz-appearance": "textfield",
    "&::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "&::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
});

const InputContainer = styled("div")({
  float: "left",
  padding: 16,
});

const Input = styled("input")({
  border: "none",
  outline: "none",
  color: "#020110",
  fontSize: 13,
  letterSpacing: "0.02em",
  borderBottom: "1px solid #DCDDE6",
  float: "left",
});

const SendIcon = styled(TelegramIcon)({
  color: "#F68F1F",
  fontSize: 20,
  fontWeight: 600,
  lineHeight: 17,
  marginLeft: 5,
  cursor: "pointer",
});

const Warning = styled("div")({
  fontSize: 11,
  fontWeight: 500,
  letterSpacing: "0.02em",
  lineHeight: "160%",
  textAlign: "left",
  color: "rgba(196, 35, 35, 0.8)",
  float: "left",
});
