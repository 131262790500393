import { createStyles, makeStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";

import background3 from "Assets/images/socials/background3.png";

export const commonStyles = makeStyles(() =>
  createStyles({
    actionChatBox: {
      zIndex: 600,
      backgroundColor: grey[200],
      backgroundImage: `url(${background3})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      position: "relative",
      top: 0,
      minHeight: "calc(100vh - var(--nav-bar-height) - 10px)",
      height: "calc(100vh - var(--nav-bar-height) - 10px)",
      maxHeight: "calc(100vh - var(--nav-bar-height) - 10px)",
      width: "100%",
      minWidth: 550,
      display: "flex",
      flexDirection: "column",
      float: "left",
      border: "8px solid white",
      borderBottom: "3px solid white",
      boxShadow: "3px 5px 5px rgba(2, 1, 16, 0.24)",
    },
    actionPhoneBox: {
      fontFamily: '"Commissioner", sans-serif',
      fontStyle: "normal",
      zIndex: 600,
      background: "#fff",
      position: "relative",
      minHeight: 600,
      height: 600,
      maxHeight: 600,
      width: 350,
      minWidth: 300,
      display: "flex",
      flexDirection: "column",
      boxShadow: "3px 5px 5px rgba(2, 1, 16, 0.24)",
      float: "left",
    },
    content: {
      padding: 20,
    },
  }),
);
