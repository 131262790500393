import { exitViewer } from "Api/chat";
import { closeBreak } from "Api/configuration";
import { saveCrmData } from "Api/crm";
import { saveCallRequest, saveChatRequest } from "Api/request";

import menuTypes from "Assets/constants/menuTypes";
import { default as useTranslation } from "Assets/hooks/useOLTranslation";

import clsx from "clsx";

import { RequestContextStore } from "Components/Layout/AppLayout";
import { StoreContext } from "Components/Layout/AppLayout";
import { ISoftStore, IStore } from "Components/Layout/interfaces";
import { menuItems } from "Components/Layout/LeftPanel/constants";
import { customerTypes } from "Components/Request/LeftPanel/constants/customerTypes";
import {
  checkFormValiation,
  getObjectForSaveApi,
} from "Components/Request/LeftPanel/CrmForm";

import useUpdateEffect from "Hook/useUpdateEffect";
import Notification from "Notifications/Notification";

import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";

import { default as BreakActions } from "Store/Actions/Breaks";
import Call from "Store/Actions/Call";
import { default as CallbackActions } from "Store/Actions/Callback";
import Cch from "Store/Actions/Cch";
import ChatBox from "Store/Actions/ChatBox";
import { default as CrmActions } from "Store/Actions/Crm";
import Note from "Store/Actions/Note";
import Request from "Store/Actions/Request";
import SoftPhoneStore from "Store/Actions/SoftPhone";
import Topic from "Store/Actions/Topic";
import { defaultStates as defaultCchStates } from "Store/Reducers/cchReducer";
import { defaultStates as defaultCrmStates } from "Store/Reducers/crmReducer";
import { defaultStates as defaultNoteStates } from "Store/Reducers/noteReducer";
import { defaultStates as defaultTopicStates } from "Store/Reducers/topicReducer";

import {
  ICustomerDataBeforeRequestSave,
  ICustomerDataBeforeRequestSaveForCall,
} from "./interfaces";
import { ActionButton } from "./styles/SaveButton";

const SaveButton = (): React.ReactElement => {
  const { translate } = useTranslation();
  const [disabled, setDisabled] = useState<boolean>(true);

  const {
    menuType,
    requestOptions,
    chosenOptions,
    callbackRequestId,
    totalCchState,
    chatBoxState,
    softPhoneState,
    totalCrmState,
    callState,
    totalTopicState,
    totalNoteState,
    breaksState,
  } = useSelector(
    ({
      requestReducer,
      callbackReducer,
      cchReducer,
      chatReducer,
      softPhoneReducer,
      crmReducer,
      callReducer,
      topicReducer,
      noteReducer,
      breakReducer,
    }: RootStateOrAny) => ({
      menuType: requestReducer.menuType,
      requestOptions: requestReducer.options,
      chosenOptions: requestReducer.chosenOptions,
      callbackRequestId: callbackReducer.requestId,
      totalCchState: cchReducer,
      chatBoxState: chatReducer,
      softPhoneState: softPhoneReducer,
      totalCrmState: crmReducer,
      callState: callReducer,
      totalTopicState: topicReducer,
      totalNoteState: noteReducer,
      breaksState: breakReducer,
    }),
  );

  const softStore: ISoftStore = useContext(StoreContext);
  const { getRequestId, getCurrentStateFromTotalState }: IStore =
    useContext(RequestContextStore);

  const crm = new CrmActions(useDispatch());
  const request = new Request(useDispatch());
  const chatBox = new ChatBox(useDispatch());
  const call = new Call(useDispatch());
  const topic = new Topic(useDispatch());
  const cch = new Cch(useDispatch());
  const note = new Note(useDispatch());
  const softPhoneStore = new SoftPhoneStore(useDispatch());
  const callback = new CallbackActions(useDispatch());
  const breaks = new BreakActions(useDispatch());

  const crmState = getCurrentStateFromTotalState(
    totalCrmState,
    defaultCrmStates,
  );
  const cchState = getCurrentStateFromTotalState(
    totalCchState,
    defaultCchStates,
  );
  const topicState = getCurrentStateFromTotalState(
    totalTopicState,
    defaultTopicStates,
  );
  const noteState = getCurrentStateFromTotalState(
    totalNoteState,
    defaultNoteStates,
  );

  const activeConversation =
    chatBoxState.conversations[chatBoxState.activeConversationId];

  const userId = localStorage.getItem("userID");
  const savingChat = menuType === menuTypes.CHAT;
  const enableEditMode =
    menuType === menuTypes.CALL && callState?.activeCallId !== 0;

  const callData = softPhoneState.lines[softPhoneState.currentLine];

  const saveCrm = async (fieldValueList): Promise<null | number> => {
    const hasPhysicalError = checkFormValiation(
      crmState.formFields,
      customerTypes.PHYSICAL,
    );

    const hasLegalError = checkFormValiation(
      crmState.formFields,
      customerTypes.LEGAL,
    );

    if (crmState.formFields.length > 0) {
      if (!hasPhysicalError && !hasLegalError) {
        const response = await saveCrmData(
          crmState.formFields,
          crmState.customerId,
          crmState.profileImage,
          crmState.identifiers,
          crmState.companies.map((company) => company.id),
          customerTypes.PHYSICAL,
          null,
          fieldValueList,
        );
        if (response && response.status === 200) {
          const result = await response.json();
          return result.id;
        }
      } else Notification.error(translate("request_fill_fields"));
    }
    return null;
  };

  const saveCrmForCall = async (
    crmState,
    fieldValueList,
  ): Promise<null | number> => {
    const hasPhysicalError = checkFormValiation(
      crmState.formFields,
      customerTypes.PHYSICAL,
    );

    const hasLegalError = checkFormValiation(
      crmState.formFields,
      customerTypes.LEGAL,
    );

    if (crmState.formFields.length > 0) {
      if (!hasPhysicalError && !hasLegalError) {
        const response = await saveCrmData(
          crmState.formFields,
          crmState.customerId,
          crmState.profileImage,
          crmState.identifiers,
          crmState.companies.map((company) => company.id),
          customerTypes.PHYSICAL,
          null,
          fieldValueList,
        );
        if (response && response.status === 200) {
          try {
            const result = await response.json();
            return result.id;
          } catch (e) {
            return null;
          }
        }
      } else Notification.error(translate("request_fill_fields"));
    }
    return null;
  };

  const saveCustomerDataBeforeRequestSave =
    async (): Promise<ICustomerDataBeforeRequestSave> => {
      const { formFields } = crmState;
      const { PHYSICAL, LEGAL } = customerTypes;

      const fieldValueList = [
        ...getObjectForSaveApi(formFields, PHYSICAL),
        ...getObjectForSaveApi(formFields, LEGAL),
      ];

      const filterFieldValueList = fieldValueList.filter(
        (info) => info.fieldName !== "",
      );

      const crmId = await saveCrm(filterFieldValueList);

      const customerFullName = filterFieldValueList.map((info) => ({
        fieldName: info.fieldName,
        values: info.values,
      }));

      return { crmId, customerFullName };
    };

  const clearUIAfterSave = (
    requestId = getRequestId(),
    menuTypeProp = menuType,
  ): void => {
    topic.clearTopicsByRequestId(menuTypeProp, +requestId);
    note.clearNotesByRequestId(menuTypeProp, +requestId);
    cch.clearTasksByRequestId(menuTypeProp, +requestId);
    softStore.savingCallId.set(null);
    crm.setCustomerData(
      menuTypeProp,
      +requestId,
      [
        {
          identifier: null,
          channelType: Object.keys(menuItems)[0],
        },
        {
          identifier: null,
          channelType: Object.keys(menuItems)[1],
        },
        {
          identifier: null,
          channelType: Object.keys(menuItems)[2],
        },
        {
          identifier: null,
          channelType: Object.keys(menuItems)[3],
        },
        {
          identifier: null,
          channelType: Object.keys(menuItems)[4],
        },
      ],
      null,
      totalCrmState.defaultFields,
      null,
      [],
    );
    request.addRequestOption(menuTypeProp, "0", {
      id: 9,
      name: "Tamamlanmamış",
    });

    if (menuTypeProp === menuTypes.CALL) {
      if (softPhoneState.lines[softPhoneState.currentLine]?.isSavedInCall) {
        return request.setShowRegistration(false);
      }

      let shouldRegistrationClose = true;
      Object.keys(softPhoneState.lines).map((key) => {
        if (
          softPhoneState.lines[key]?.sipCallId &&
          softPhoneState.lines[key]?.sipCallId !==
            softStore.terminatedCallId.get()
        )
          shouldRegistrationClose = false;
      });

      shouldRegistrationClose && request.setShowRegistration(false);
    } else request.setShowRegistration(false);
  };

  const getClosedLine = (): string => {
    let closedLine = null;
    let sipCallId = null;

    if (callState.activeCallId !== 0) {
      return callState.activeCallId;
    }

    if (softStore.terminatedCallId.get()) {
      sipCallId = softStore.terminatedCallId.get();
    } else {
      return softPhoneState.currentLine;
    }

    if (callState.activeCallId === 0)
      Object.keys(softPhoneState.lines).map((key) => {
        if (softPhoneState.lines[key]?.sipCallId === sipCallId)
          closedLine = key;
      });

    return closedLine;
  };

  const saveCustomerDataBeforeRequestSaveForCall = async (
    closedLine,
  ): Promise<ICustomerDataBeforeRequestSaveForCall> => {
    const savingCrmData = closedLine
      ? totalCrmState[menuTypes.CALL][closedLine]
      : crmState;

    const { formFields } = savingCrmData,
      { PHYSICAL, LEGAL } = customerTypes;

    const fieldValueList = [
      ...getObjectForSaveApi(formFields, PHYSICAL),
      ...getObjectForSaveApi(formFields, LEGAL),
    ];

    const filterFieldValueList = fieldValueList.filter(
      (info) => info.fieldName !== "",
    );

    const crmId = await saveCrmForCall(savingCrmData, filterFieldValueList);

    const customerFullName = filterFieldValueList.map((info) => ({
      fieldName: info.fieldName,
      values: info.values,
    }));

    return {
      crmId,
      customerFullName,
      requestId: closedLine ?? getRequestId(),
    };
  };

  const saveCall = async (menuTypeProp = menuType): Promise<void> => {
    const closedLine = getClosedLine();

    // track if acw call is saved before it is over
    if (localStorage.getItem("autoAcw") === "true") {
      softPhoneStore.setSaveBeforeACWCallOver(true);
      softPhoneStore.setWillBeSaveAfterACWCallOver(false);
    }

    if (softPhoneState.isBreakOnHold) {
      softPhoneStore.setSaveBeforeHoldCallOver(true);
    }

    // if call is a spy call, don't save the call as a request and clear registration page
    if (softPhoneState.isSpyCallActive) {
      request.setShowRegistration(false);
      clearUIAfterSave(undefined, menuTypeProp);
      return;
    }

    // if request is saved during call,then there is no need for duplicate save, just return void

    if (softPhoneState.lines[closedLine]?.isSavedInCall) {
      softStore.terminatedCallId.set(null);
      return;
    }

    // if request is being saved during call, update the state as below
    if (!softStore.terminatedCallId.get() && callData?.sipCallId) {
      softPhoneStore.setSavedInCallAtLine(closedLine, true);
    }

    // determine the ids to save
    let id = null,
      specialId = null;

    if (callState.activeCallId === 0) {
      if (softStore.terminatedCallId.get())
        id = softStore.terminatedCallId.get();
      else if (callData.sipCallId) id = callData.sipCallId;
    } else {
      id = callState.calls[callState.activeCallId].sipCallId;
      specialId = callState.activeCallId;
    }

    // save and extract customer data for request save
    const { crmId, customerFullName, requestId } =
      await saveCustomerDataBeforeRequestSaveForCall(closedLine);

    const ids = {
      id: specialId,
      sipCallId: id,
    };

    const defaultStatus = requestOptions[menuTypeProp][1];
    const classifiers = closedLine
        ? totalTopicState[menuTypes.CALL][closedLine]?.savedPaths
        : topicState.savedPaths,
      customerNote = closedLine
        ? totalNoteState[menuTypes.CALL][closedLine]?.newNote
        : noteState?.newNote,
      tasks = closedLine
        ? totalCchState[menuTypes.CALL][closedLine]?.allSavedCchs
        : cchState?.allSavedCchs,
      status = chosenOptions[menuTypes.CALL][closedLine ?? 0] ?? defaultStatus;
    const data = {
      ...ids,
      specialId,
      statusDetails: status.name,
      statusDetailsId: status.id,
      classifiers,
      customerNote,
      tasks,
      communicationType: "SIPPHONE",
      customerData: {
        id: crmId,
        fieldValueList: customerFullName,
      },
    };

    request.setRequestSending(true);

    const response = await saveCallRequest(data, callbackRequestId);

    request.setRequestSending(false);

    if (response) {
      callback.setCallbackRequestId("");

      clearUIAfterSave(requestId, menuTypeProp);
      softStore.terminatedCallId.set(null);
      crm.setCustomerCif(null);
      crm.setAccountNumber(null);
      // if call is active and it has already been saved,
      // then close acw break if there is one
      // if call is not active then remove the call data from callState
      if (callState.activeCallId === 0) {
        if (
          !softPhoneState.isCallContinuing &&
          !softPhoneState.isSavedInCall &&
          (localStorage.getItem("autoAcw") === "true" ||
            softPhoneState.willBeSavedAfterHoldCallOver) &&
          parseInt(breaksState.selectedBreakId) === callState.acwBreakId
        ) {
          const response = await closeBreak(
            +userId,
            +callState.acwBreakId,
            null,
          );
          if (response) {
            breaks.setSelectedBreakId("default");
            breaks.setCloseBreakId("null");
          }
        }
      } else call.removeCallById(callState.activeCallId);
    }
  };

  // if chat is live then send a request to the server ('/endChat')
  // if not just remove the saved conversation data from the state
  const endChatAfterSave = (): void => {
    activeConversation && activeConversation.editMode === true
      ? chatBox.endChat(
          chatBoxState.activeConversationId,
          parseInt(localStorage.getItem("userID")),
        )
      : chatBox.tryToEndChat(
          chatBoxState.activeConversationId,
          parseInt(localStorage.getItem("userID")),
        );
    exitViewer(chatBoxState.activeConversationId);
    chatBox.setChatEnded(false);
  };

  const saveChat = async (): Promise<void> => {
    // determine the ids to save
    let id = null;
    let specialId = null;
    const isChatSaved =
      chatBoxState.messages[chatBoxState.activeConversationId]?.isChatSaved;

    // if chat is already saved,then there is no need for duplicate save, just return void
    if (isChatSaved && chatBoxState.isChatEnded) {
      endChatAfterSave();
      chatBox.setChatFullWidth(false);
      return;
    }

    if (savingChat && activeConversation) {
      id = activeConversation.requestId;
      specialId = activeConversation.requestId;
    }

    const { crmId, customerFullName } =
        await saveCustomerDataBeforeRequestSave(),
      ids = {
        id,
        sipCallId: null,
      },
      status =
        chosenOptions[menuTypes.CHAT][getRequestId()] ??
        requestOptions[menuType][1];

    const data = {
      ...ids,
      specialId,
      statusDetails: status.name,
      statusDetailsId: status.id,
      classifiers: topicState.savedPaths,
      communicationType: null,
      customerNote: noteState.newNote,
      tasks: cchState.allSavedCchs,
      customerData: {
        id: crmId,
        fieldValueList: customerFullName,
      },
    };

    request.setRequestSending(true);

    const response = await saveChatRequest(data);

    request.setRequestSending(false);

    if (response) {
      request.setIsEditRequest(true);
      clearUIAfterSave();
      chatBox.setChatSaved(chatBoxState.activeConversationId, true);
      if (chatBoxState.isChatEnded) {
        endChatAfterSave();
        chatBox.setChatFullWidth(false);
        Notification.success(translate("request_chat_ended"));
      } else if (!chatBoxState.isChatEnded) {
        Notification.success(translate("request_chat_saved"));
      }
    } else {
      Notification.success(translate("error"));
      chatBox.setChatSaved(chatBoxState.activeConversationId, false);
    }
  };

  const handleClick = (): void => {
    if (!disabled) menuType === menuTypes.CALL ? saveCall() : saveChat();
  };

  useEffect(() => {
    Object.keys(softPhoneState.lines).map((key) => {
      if (
        softPhoneState.lines[key]?.sipCallId ===
          softStore.terminatedCallId.get() &&
        !softPhoneState.lines[key]?.isAnswered &&
        !softPhoneState.isBreakOnHold
      ) {
        clearUIAfterSave(undefined, menuType.CALL);
        softStore.terminatedCallId.set(null);
        crm.setCustomerCif(null);
        crm.setAccountNumber(null);
      }
      if (
        softPhoneState.lines[key]?.sipCallId ===
          softStore.terminatedCallId.get() &&
        softPhoneState.lines[key]?.isAnswered &&
        localStorage.getItem("autoAcw") !== "true" &&
        !softPhoneState.isBreakOnHold
      )
        saveCall("CALL");
    });
  }, [softStore.terminatedCallId.get()]);

  useEffect(() => {
    chatBoxState.isChatEnded && saveChat();
  }, [chatBoxState.isChatEnded]);

  useUpdateEffect(() => {
    const preconditions =
      callState.activeCallId === 0 &&
      !callData?.sipCallId &&
      !chatBoxState.activeConversationId;
    if (softStore?.newIncomingCallId.get()) {
      menuType === menuTypes.CALL && setDisabled(true);
    } else if (localStorage.getItem("autoAcw") === "false") {
      softPhoneState.willBeSavedAfterHoldCallOver
        ? setDisabled(
            preconditions && !softPhoneState.willBeSavedAfterHoldCallOver,
          )
        : setDisabled(preconditions);
    } else if (localStorage.getItem("autoAcw") === "true")
      setDisabled(preconditions && !softPhoneState.willBeSavedAfterACWCallOver);
  }, [
    callState.activeCallId,
    chatBoxState.activeConversationId,
    callData?.sipCallId,
    softPhoneState.willBeSavedAfterACWCallOver,
    softPhoneState.willBeSavedAfterHoldCallOver,
    softStore?.newIncomingCallId.get(),
  ]);

  return (
    <ActionButton
      className={clsx({
        disabled,
        ["disable"]: enableEditMode
          ? !enableEditMode
          : softPhoneState.isSpyCallActive,
      })}
      onClick={handleClick}>
      {translate("save")}
    </ActionButton>
  );
};

export default SaveButton;

SaveButton.displayName = "SaveButton";
