/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { reportAxiosInstance as axios } from "Api/axios";

import WebConf, { ReportServerUrl } from "Config/WebConf";

import { filterDateTypes } from "Pages/Report/ClassificatorReport/constants";
import { IGetFCR } from "Pages/Report/FCRReport/interfaces";
import { formatDateThree } from "Utils/Utils";
import { convertToSeconds } from "Utils/UtilsFunc";

// GET
export const getTraceStatuses = (): Promise<any> =>
  axios
    .get(ReportServerUrl + WebConf.report.getTraceStatuses)
    .then((response) => response && response.data);

export const getAgentDailyReport = (): Promise<any> =>
  axios
    .get(ReportServerUrl + WebConf.report.agentDaily)
    .then((response) => response && response.data);

export const getCrmDataReportFilters = (): Promise<any> =>
  axios
    .get(ReportServerUrl + WebConf.report.getCrmDataReportFilters)
    .then((response) => response && response.data);

// POST
export const trendCategoryListByDate = (
  count: number,
  fromDateTime: any,
  toDateTime: any,
  communicationType: string,
) => {
  const body = {
    fromDateTime: fromDateTime ? formatDateThree(fromDateTime) : "",
    toDateTime: toDateTime ? formatDateThree(toDateTime) : "",
    communicationType: communicationType === "all" ? null : communicationType,
  };

  return axios
    .post(
      ReportServerUrl + WebConf.report.trendCategoryListByDate + count,
      body,
    )
    .then((response) => response && response.data);
};

export const getCategoryCountByDate = (
  dateType: filterDateTypes,
  fromDateTime: any,
  toDateTime: any,
  communicationType: string,
  topics: Array<string>,
) => {
  fromDateTime = fromDateTime ? formatDateThree(fromDateTime) : "";
  toDateTime = toDateTime ? formatDateThree(toDateTime) : "";
  communicationType = communicationType === "all" ? null : communicationType;
  const body = {
    fromDateTime,
    toDateTime,
    communicationType,
    topics,
  };

  return axios
    .post(
      ReportServerUrl +
        WebConf.report.getCategoryCountByDate +
        "?" +
        "per=" +
        dateType,
      body,
    )
    .then((response) => response && response.data);
};

export const categoryUsingDiagramListByDate = (
  fromDateTime: any,
  toDateTime: any,
  pathList: Array<string>,
  communicationType: string,
) => {
  const body = {
    fromDateTime: fromDateTime ? formatDateThree(fromDateTime) : "",
    toDateTime: toDateTime ? formatDateThree(toDateTime) : "",
    pathList: pathList,
    communicationType: communicationType === "all" ? null : communicationType,
  };

  return axios
    .post(ReportServerUrl + WebConf.report.categoryUsingDiagramListByDate, body)
    .then((response) => response && response.data);
};

export const getQueueReport = async (
  fromDateTime: any,
  toDateTime: any,
  queueNameList: string[],
  listFieldName: string[],
) => {
  const body = {
    fromDateTime: fromDateTime ? formatDateThree(fromDateTime) : "",
    toDateTime: toDateTime ? formatDateThree(toDateTime) : "",
    queueNameList,
    listFieldName,
  };

  return axios
    .post(ReportServerUrl + WebConf.report.getQueueReport, body)
    .then((response) => response && response.data);
};

export const getCallQueueReport = async (
  fromDateTime: any,
  toDateTime: any,
  queueNameList: string[],
  serviceLevel: string,
) => {
  const body = {
    fromDateTime: fromDateTime ? formatDateThree(fromDateTime) : "",
    toDateTime: toDateTime ? formatDateThree(toDateTime) : "",
    queueNameList,
    serviceLevel,
  };

  return axios
    .post(ReportServerUrl + WebConf.report.getCallQueueReport, body)
    .then((response) => (response ? response.data : []));
};

export const getChatQueueReport = async (
  fromDateTime: any,
  toDateTime: any,
  queueNameList: string[],
) => {
  const body = {
    fromDateTime: fromDateTime ? formatDateThree(fromDateTime) : "",
    toDateTime: toDateTime ? formatDateThree(toDateTime) : "",
    queueNameList,
  };

  return axios
    .post(ReportServerUrl + WebConf.report.getChatQueueReport, body)
    .then((response) => (response ? response.data : []));
};

export const getAgentWorkReport = async (
  fromDate: any,
  toDate: any,
  selectedUserList: number[],
  page: number,
  size: number,
) => {
  const body = {
    fromDateTime: fromDate ? formatDateThree(fromDate) : "",
    toDateTime: toDate ? formatDateThree(toDate) : "",
    userIds: selectedUserList,
    page,
    size,
  };

  return axios
    .post(ReportServerUrl + WebConf.report.getAgentWorkReport, body)
    .then((response) => response && response.data);
};

export const getCallReport = async (
  fromDate: any,
  toDate: any,
  toTalkDuration: any,
  fromTalkDuration: any,
  selectedUserList: number[],
  selectedStatusTypes: string[],
  selectedHangUpBy: string[],
  selectedTypes: string[],
  selectedFrwIvr: string,
  page: number,
  size: number,
) => {
  const body = {
    fromDateTime: fromDate ? formatDateThree(fromDate) : "",
    toDateTime: toDate ? formatDateThree(toDate) : "",
    talkDurationTo: toTalkDuration ? convertToSeconds(toTalkDuration) : null,
    talkDurationFrom: fromTalkDuration
      ? convertToSeconds(fromTalkDuration)
      : null,
    userIds: selectedUserList,
    registrations: selectedStatusTypes,
    forwardedIvr:
      selectedFrwIvr === "All" ? null : selectedFrwIvr === "Yes" ? true : false,
    hangUpBys: selectedHangUpBy,
    types: selectedTypes,
    page,
    size,
  };

  return axios
    .post(ReportServerUrl + WebConf.report.getCallReport, body)
    .then((response) => response && response.data);
};

export const getTaskReport = async (
  fromDate: any,
  toDate: any,
  page: number,
  size: number,
) => {
  const body = {
    fromDateTime: fromDate ? formatDateThree(fromDate) : "",
    toDateTime: toDate ? formatDateThree(toDate) : "",
    page,
    size,
  };

  return axios
    .post(ReportServerUrl + WebConf.report.getTaskReport, body)
    .then((response) => response && response.data);
};

export const getIvrReport = async (
  fromDate: any,
  toDate: any,
  selectedOperation: string,
  selectedStatusType: string,
  page: number,
  size: number,
) => {
  const body = {
    fromDateTime: fromDate ? formatDateThree(fromDate) : "",
    toDateTime: toDate ? formatDateThree(toDate) : "",
    // operation: selectedOperation === "ALL" ? null : selectedOperation,
    status: selectedStatusType === "ALL" ? null : selectedStatusType,
    page,
    size,
  };

  return axios
    .post(ReportServerUrl + WebConf.report.getIvrReport, body)
    .then((response) => response && response.data);
};

export const getTaskReportExcell = async (
  fromDate: any,
  toDate: any,
  page: number,
  size: number,
) => {
  const body = {
    fromDateTime: fromDate ? formatDateThree(fromDate) : "",
    toDateTime: toDate ? formatDateThree(toDate) : "",
    page,
    size,
  };

  return axios
    .post(ReportServerUrl + WebConf.report.getTaskReportExcel, body)
    .then((response) => response && response.data);
};

export const getTaskReport2 = async (
  fromDate: any,
  toDate: any,
  doneOnTime: null | boolean,
  reasons: string[],
  page: number,
  size: number,
) => {
  const body = {
    fromDateTime: fromDate ? formatDateThree(fromDate) : "",
    toDateTime: toDate ? formatDateThree(toDate) : "",
    doneOnTime,
    reasons,
    page,
    size,
  };

  return axios
    .post(ReportServerUrl + WebConf.report.getTaskReport2, body)
    .then((response) => response && response.data);
};

export const getAgentCallReport = async (
  fromDate: any,
  toDate: any,
  selectedUserList: number[],
  listFieldName: string[],
) => {
  const body = {
    from: fromDate ? formatDateThree(fromDate) : "",
    to: toDate ? formatDateThree(toDate) : "",
    userIds: selectedUserList,
    listFieldName,
  };

  return axios
    .post(ReportServerUrl + WebConf.report.getAgentCallReport, body)
    .then((response) => response && response.data);
};

export const getTraceByLocationReport = async (
  fromDateTime: any,
  toDateTime: any,
  statusIds: any[],
  userIds: number[],
  page: number,
  size: number,
) => {
  const body = {
    fromDateTime: fromDateTime ? formatDateThree(fromDateTime) : "",
    toDateTime: toDateTime ? formatDateThree(toDateTime) : "",
    statusIds: statusIds.length > 0 ? statusIds : null,
    userIds: userIds.length > 0 ? userIds : null,
    page,
    size,
  };

  return axios
    .post(ReportServerUrl + WebConf.report.getTraceByLocationReport, body)
    .then((response) => response && response.data);
};

export const getAgentSMMReport = async (
  fromDate: any,
  toDate: any,
  userIds: number[],
  reportType: string,
) => {
  const body = {
    fromDateTime: fromDate ? formatDateThree(fromDate) : "",
    toDateTime: toDate ? formatDateThree(toDate) : "",
    userIds: userIds.length > 0 ? userIds : null,
    reportType: reportType ?? "",
  };

  return axios
    .post(ReportServerUrl + WebConf.report.getAgentSMMReport, body)
    .then((response) => response && response.data);
};

export const getChatReport = async (
  fromDate: any,
  toDate: any,
  userIds: number[],
  types: string[],
  page: number,
  size: number,
) => {
  const body = {
    fromDateTime: fromDate ? formatDateThree(fromDate) : "",
    toDateTime: toDate ? formatDateThree(toDate) : "",
    userIds: userIds.length > 0 ? userIds : null,
    types: types.length > 0 ? types : null,
    page,
    size,
  };

  return axios
    .post(ReportServerUrl + WebConf.report.getChatReport, body)
    .then((response) => response && response.data);
};

export const getAgentSessionReport = async (
  fromDate: any,
  toDate: any,
  selectedUserList: number[],
) => {
  const body = {
    from: fromDate ? formatDateThree(fromDate) : "",
    to: toDate ? formatDateThree(toDate) : "",
    userIds: selectedUserList,
  };

  return axios
    .post(ReportServerUrl + WebConf.report.getAgentSessionReport, body)
    .then((response) => response && response.data);
};

export const getSummaryCallReport = async (
  fromDateTime: any,
  toDateTime: any,
  queueGroups: string[],
  dateRange: string,
) => {
  const body = {
    fromDateTime: fromDateTime ? formatDateThree(fromDateTime) : "",
    toDateTime: toDateTime ? formatDateThree(toDateTime) : "",
    queues: queueGroups,
  };

  return axios
    .post(
      ReportServerUrl +
        WebConf.report.getSummaryCallReport +
        "?per=" +
        dateRange,
      body,
    )
    .then((response) => response && response.data);
};

export const getSummaryChatReport = async (
  fromDateTime: any,
  toDateTime: any,
  dateRange: string,
) => {
  const body = {
    fromDateTime: fromDateTime ? formatDateThree(fromDateTime) : "",
    toDateTime: toDateTime ? formatDateThree(toDateTime) : "",
  };

  return axios
    .post(
      ReportServerUrl +
        WebConf.report.getSummaryChatReport +
        "?per=" +
        dateRange,
      body,
    )
    .then((response) => response && response.data);
};

export const getMissedCallReport = async (
  fromDate: any,
  toDate: any,
  selectedUserList: number[],
  queueNameList: string[],
  requestId: string,
  page: number,
  size: number,
) => {
  const body = {
    fromDateTime: fromDate ? formatDateThree(fromDate) : "",
    toDateTime: toDate ? formatDateThree(toDate) : "",
    userIds: selectedUserList,
    queueList: queueNameList,
    requestId,
    page,
    size,
  };

  return axios
    .post(ReportServerUrl + WebConf.report.getMissedCallReport, body)
    .then((response) => response && response.data);
};

export const getSmsSenderReport = (
  fromDate: Date,
  toDate: Date,
  number: string,
  userIds: number[],
  page: number,
  size: number,
) => {
  const data = {
    fromDateTime: fromDate ? formatDateThree(fromDate) : "",
    toDateTime: toDate ? formatDateThree(toDate) : "",
    number,
    userIds,
    page,
    size,
  };

  return axios
    .post(ReportServerUrl + WebConf.report.getSmsSenderReport, data)
    .then((response) => response && response.data);
};

export const getFCRReport = (
  fromDate: Date,
  toDate: Date,
  channelId: string,
  topic: string,
  type: string,
  page: number,
  size: number,
): Promise<IGetFCR> => {
  const data = {
    fromDate: fromDate ? formatDateThree(fromDate) : "",
    toDate: toDate ? formatDateThree(toDate) : "",
    channelId: channelId.length > 0 ? channelId : null,
    topic: topic.length > 0 && topic !== "none" ? topic : null,
    type,
    page,
    size,
  };

  return axios
    .post(ReportServerUrl + WebConf.report.getFCRReport, data)
    .then((response) => response && response.data);
};

export const getFCRValue = (
  fromDate: Date,
  toDate: Date,
  type: string,
): Promise<number> => {
  const data = {
    fromDate: fromDate ? formatDateThree(fromDate) : "",
    toDate: toDate ? formatDateThree(toDate) : "",
    type,
  };

  return axios
    .post(ReportServerUrl + WebConf.report.getFCRValue, data)
    .then((response) => response && response.data);
};

export const getCrmDataReport = async (
  fromDate: any,
  toDate: any,
  crmData: any,
  classifier: any,
  page: number,
  size: number,
) => {
  const body = {
    fromDateTime: fromDate ? formatDateThree(fromDate) : "",
    toDateTime: toDate ? formatDateThree(toDate) : "",
    crmData,
    classifier,
    page,
    size,
  };

  return axios
    .post(ReportServerUrl + WebConf.report.getCrmDataReport, body)
    .then((response) => response && response.data);
};
