/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-empty-function */
import { getOptionsByDictionaryId } from "Api/configuration";

import Form from "Components/Form/Form";
import FormDateTimePicker from "Components/Form/FormDateTimePicker";
import FormInput from "Components/Form/FormInput";
import FormInputDiapason from "Components/Form/FormInputDiapason";
import CrmInput from "Components/Request/LeftPanel/CrmInput";
import CustomInput from "Components/Request/LeftPanel/CustomInput";
import DateInput from "Components/Request/LeftPanel/DateInput";
import IdentifierInput from "Components/Request/LeftPanel/IdentifierInput";
import NameInput from "Components/Request/LeftPanel/NameInput";
import SelectInput from "Components/Request/LeftPanel/SelectInput";

import React from "react";
import { formatDate, formatDateTime, formatDiapason } from "Store/utility";

import { customerTypes } from "./constants/customerTypes";
import menuItems from "./constants/menuItems";
import {
  ICustomerField,
  IFormField,
  IIdentifierField,
  IUpdatedCustomerField,
} from "./interfaces";
import fieldTypes from "../../Form/fieldTypes";

export const getElementFormattedValues = (field): Array<any> => {
  const values = [];
  if (typeof field.fieldValue === "string" && field.fieldValue) {
    values.push({
      value: field.fieldValue,
    });
  } else if (
    Object.prototype.toString.call(field.fieldValue) === "[object Array]"
  ) {
    field.fieldValue.forEach((val) => {
      values.push({
        value: val.name,
      });
    });
  } else if (typeof field.fieldValue === "object") {
    if (field.fieldType && field.fieldType.typeName === fieldTypes.DATE) {
      values.push({
        value: formatDate(field.fieldValue),
      });
    } else if (
      field.fieldType &&
      field.fieldType.typeName === fieldTypes.DATETIME
    ) {
      values.push({
        value: formatDateTime(field.fieldValue),
      });
    } else if (
      field.fieldType &&
      field.fieldType.typeName === fieldTypes.DATETIME_RANGE
    ) {
      values.push({
        value: formatDiapason(field.fieldValue),
      });
    } else {
      if (field.fieldValue)
        values.push({
          value: field.fieldValue.name,
        });
    }
  }
  return values;
};

export const getObjectForSaveApi = (
  formFields,
  customerType: customerTypes,
  onlyIncludesInCapture = false,
): Array<any> => {
  const fields = [];
  formFields.forEach((field) => {
    const values = getElementFormattedValues(field);

    if (field.customerType === customerType) {
      if (onlyIncludesInCapture && !field.includesInCapture) return;
      const element = {
        id: field.custom ? null : field.id,
        fieldName: field.fieldName,
        values,
        fieldTypeId: field.fieldType.id,
        activeColumn: field.activeColumn,
        mandatory: field.mandatory,
        includesInCapture: field.includesInCapture,
        multiple: field.multiple,
        editable: field.editable,
        optionsCanBeAdded: field.optionsCanBeAdded,
      };
      fields.push(element);
    }
  });
  return fields;
};

export const checkFormValiation = (
  formFields,
  customerType: customerTypes | null = null,
  customFormFields = null,
): boolean => {
  const form = new Form();
  let hasError = false;
  formFields = customFormFields ? customFormFields : formFields;
  formFields.map((field) => {
    if (customerType && field.customerType !== customerType) return null;
    form.validateValue({
      required: field.mandatory,
      value: field.fieldValue,
      regex: field.fieldType.regex,
      setError: (error: boolean) => {
        if (error) hasError = true;
      },
    });
  });
  return hasError;
};

export const getIndentifierLabel = (channelType): string => {
  switch (channelType) {
    case Object.keys(menuItems)[0]: //SIPPHONE
      return "Phone Number";
    case Object.keys(menuItems)[1]: //FB
      return "Facebook";
    case Object.keys(menuItems)[2]: //WHATSAPP
      return "Whatsapp";
    case Object.keys(menuItems)[3]: //TELEGRAM
      return "Telegram";
    case Object.keys(menuItems)[4]: //WEBCHAT
      return "Webchat";
    case Object.keys(menuItems)[5]: //INSTAGRAM
      return "Instagram";
    default:
      break;
  }
};

export const getIdentifierField = (
  field: IIdentifierField,
): React.ReactElement => {
  const label = getIndentifierLabel(field.channelType);
  if (label)
    return (
      <IdentifierInput
        key={field.channelType}
        id={field.channelType}
        handleChange={(value, id, label) =>
          field.onInputChange(value, id, label)
        }
        fieldValue={field.value}
        label={label}
      />
    );
};

const getNonMatchingObjects = (
  fields: Array<ICustomerField>,
  updatedfields: Array<IUpdatedCustomerField>,
): Array<ICustomerField> => {
  return fields.filter(
    (field) =>
      !updatedfields.some(
        (updatedfield) => field.fieldName === updatedfield.fieldName,
      ),
  );
};

export const fillDefaultFieldWithCustomerFieldsValues = (
  defaultFields,
  fields,
): Array<IUpdatedCustomerField> => {
  const updatedFieldValueList = JSON.parse(JSON.stringify(defaultFields));
  const allFieldValueList = JSON.parse(JSON.stringify(defaultFields));

  const nonMatchingObjects = getNonMatchingObjects(
    fields,
    updatedFieldValueList,
  );
  const newFieldValueList = allFieldValueList.slice(
    0,
    nonMatchingObjects.length,
  );

  fields.forEach((field) => {
    updatedFieldValueList.forEach((currentField) => {
      if (field.fieldName === currentField.fieldName) {
        currentField.id = field.id;
        if (
          currentField.fieldType.typeName === fieldTypes.SELECT ||
          currentField.fieldType.typeName === fieldTypes.CHECKBOX
        ) {
          if (!currentField.multiple) {
            currentField.fieldValue = field.values[0]
              ? field.values[0].value
              : "";
          } else {
            currentField.fieldValue = field.values;
          }
        } else {
          currentField.fieldValue = field.values[0]
            ? field.values[0].value
            : "";
        }
      }
    });
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  nonMatchingObjects?.forEach((field, index) => {
    if (newFieldValueList[index]) {
      newFieldValueList[index].id = field.id;
      newFieldValueList[index].fieldName = field.fieldName;
      newFieldValueList[index].fieldValue = field.values[0]
        ? field.values[0].value
        : "";
    }
  });

  return [...updatedFieldValueList, ...newFieldValueList];
};

export const setOptionsByDictionaryId = async (
  dictionaryId: number,
  onOptionsFetched = (_dictionaryId, _options) => {},
): Promise<void> => {
  const data = await getOptionsByDictionaryId(dictionaryId);
  onOptionsFetched(dictionaryId, data);
};

export const getFieldNameRow = (formField: IFormField): React.ReactElement => {
  const fieldId = formField.field.id ? formField.field.id.toString() : null;
  const rowKey =
    formField.field.fieldName +
    formField.field.customerType +
    formField.field.id;

  const element = (
    <NameInput
      id={fieldId}
      fieldValue={formField.field.fieldValue}
      label={formField.field.fieldName}
      handleChange={formField.setFieldValue}
    />
  );
  return element;
};

export const getFieldByfieldType = (
  formField: IFormField,
): React.ReactElement => {
  const fieldId = formField.field.id ? formField.field.id.toString() : null;

  if (
    formField.field.customerType !== formField.fieldType &&
    formField.fieldType !== customerTypes.ALL
  )
    return null;

  if (formField.field.custom === true)
    return (
      <CustomInput
        id={fieldId}
        handleChange={formField.setFieldValue}
        handlePlaceHolderChange={formField.setFieldPlaceHolder}
        regex={formField.field.fieldType.regex}
        required={formField.field.mandatory}
        errorText={formField.field.fieldType.errorText}
        size={"small"}
        value={formField.field.fieldValue}
        label={formField.field.fieldName}
        checkValidation={formField.shouldCheckValidation}
      />
    );

  let element = (
    <CrmInput
      id={fieldId}
      fieldValue={formField.field.fieldValue}
      label={formField.field.fieldName}
      handleChange={formField.setFieldValue}
    />
  );

  switch (formField.field.fieldType.typeName) {
    case fieldTypes.LARGE_TEXT:
    case fieldTypes.NUMBER:
      element = (
        <FormInput
          handleKeyDown={(event) => {
            if (event.key === "Tab") event.preventDefault();
          }}
          fieldType={formField.field.fieldType.typeName}
          id={fieldId}
          handleChange={formField.setFieldValue}
          regex={formField.field.fieldType.regex}
          required={formField.field.mandatory}
          errorText={formField.field.fieldType.errorText}
          size={"small"}
          value={formField.field.fieldValue}
          label={formField.field.fieldName}
          checkValidation={formField.shouldCheckValidation}
        />
      );
      break;
    case fieldTypes.DATE:
      element = (
        <DateInput
          id={fieldId}
          handleChange={formField.setFieldValue}
          regex={formField.field.fieldType.regex}
          required={formField.field.mandatory}
          errorText={formField.field.fieldType.errorText}
          value={formField.field.fieldValue}
          label={formField.field.fieldName}
          checkValidation={formField.shouldCheckValidation}
        />
      );
      break;
    case fieldTypes.DATETIME:
      element = (
        <FormDateTimePicker
          id={fieldId}
          handleChange={formField.setFieldValue}
          regex={formField.field.fieldType.regex}
          required={formField.field.mandatory}
          errorText={formField.field.fieldType.errorText}
          value={formField.field.fieldValue}
          label={formField.field.fieldName}
          checkValidation={formField.shouldCheckValidation}
        />
      );
      break;
    case fieldTypes.DATETIME_RANGE:
      element = (
        <FormInputDiapason
          fieldType={formField.field.fieldType.typeName}
          id={fieldId}
          handleChange={formField.setFieldValue}
          regex={formField.field.fieldType.regex}
          required={formField.field.mandatory}
          errorText={formField.field.fieldType.errorText}
          size={"small"}
          value={formField.field.fieldValue}
          label={formField.field.fieldName}
          checkValidation={formField.shouldCheckValidation}
        />
      );
      break;
    case fieldTypes.CHECKBOX:
    case fieldTypes.SELECT:
      const options = formField.field.options ? formField.field.options : [];
      element = (
        <SelectInput
          id={fieldId}
          handleChange={formField.setFieldValue}
          required={formField.field.mandatory}
          regex={formField.field.fieldType.regex}
          errorText={formField.field.fieldType.errorText}
          options={options}
          handleAddOption={() => {}}
          checkValidation={formField.shouldCheckValidation}
          optionCanBeAdded={formField.field.optionsCanBeAdded}
          multiple={formField.field.fieldType.isMultiple}
          value={formField.field.fieldValue}
          label={formField.field.fieldName}
          onFocus={formField.onSelectFocus}
          serverSide
        />
      );
      break;
  }
  return element;
};
